import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const DyingLight2StayHuman = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";

  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #FF0B10, #E72C2F, #CF0004, #D81014, #EF1115 )",
        icons: "#FF0B10",
        navClass: "dl2-stay-human",
      }}
      seo={{
        title: "Dying Light 2 STAY HUMAN",
        headerTitle: "dl2-sh",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/dl2-stay-human_og-image.jpg"),
      }}
      // langSwitch={{
      //     langKey: "en",
      //     langSlug: "/en/projects/dl2-stay-human/",
      // }}
    >
      <PortfolioHeader name="dl2-stay-human" height="276" />
      <section className="container-fluid dl2-sh__section2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Dying Light 2</h1>
              <ul>
                <li>Strona internetowa</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                Dead Island, Call of Juarez, ale przede wszystkim Dying Light to
                najbardziej cenione, światowe hity autorstwa jednego z dwóch
                największych graczy na rynku gier w Polsce, firmy Techland.
                Zrealizowaliśmy wspólnie już kilka projektów, jednak to praca
                przy Dying Light 2 była dla nas największym z dotychczasowych
                wyzwań.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid dl2-sh__section3">
        <div className="container">
          <div className="row">
            <div className="col-12 dl2-sh__list dl2-sh__list--intro">
              <h2>Konkret</h2>
              <p>
                Dying Light 2: Stay Human to długo wyczekiwana przez graczy na
                całym świecie, kontynuacją światowego bestsellera - Dying Light
                autorstwa wrocławskiego giganta, firmy Techland, który powierzył
                nam zadanie wdrożenia oraz zapewnienia około premierowego
                supportu technicznego oficjalnej, światowej strony gry.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="scroll_wrapper scroll_wrapper--macbook">
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/dl2-stay-human_full.jpg")}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1 dl2-sh__section3--mobiles">
              <img
                src={require("../../assets/img/portfolio/dl2-stay-human_mobile.png")}
                alt="Day light 2 Mobile phone screenshot"
              />
              <img
                src={require("../../assets/img/portfolio/dl2-stay-human_lang.png")}
                alt="Day light 2 list of available languages"
              />
            </div>
            <div className="col-md-6 order-1 order-md-2 dl2-sh__section3--description dl2-sh__list">
              <h2>Technologia</h2>
              <p>
                Wdrożyć stronę w technologii, która będzie gotowa na zapewnienie
                perfekcyjnej wydajności nawet przy skrajnie dużym natężeniu
                ruchu użytkowników z całego świata.
              </p>
              <h2>Wersje językowe</h2>
              <p>
                Wdrożyć serwis w 21 automatycznie rozpoznających użytkownika
                wersjach językowych, w tym w m.in. w wersji arabskiej, tajskiej,
                chińskiej i węgierskiej.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid dl2-sh__section4 px-0">
        <img
          src={require("../../assets/img/portfolio/dl2-stay-human_banner.jpg")}
          alt="Day light 2 screenshot"
        />
        <img
          src={require("../../assets/img/portfolio/dl2-stay-human_hero.png")}
          alt="Day light 2 hero"
        />
        <img
          src={require("../../assets/img/portfolio/dl2-stay-human_logo.svg")}
          alt="Day light 2 screenshot"
        />
      </section>

      <section className="container-fluid dl2-sh__section5 px-0">
        <img
          className="d-none d-md-block"
          src={require("../../assets/img/portfolio/dl2-stay-human_background.jpg")}
          alt="Day light 2 Stay Human"
        />
        <div className="row dl2-sh__section5__screens">
          <div className="col-md-6 dl2-sh__section5__col-left">
            <img
              src={require("../../assets/img/portfolio/dl2-stay-human_screen1.png")}
              alt="Day light 2 screenshots"
            />
            <img
              src={require("../../assets/img/portfolio/dl2-stay-human_screen2.png")}
              alt="Day light 2 screenshots"
            />
          </div>
          <div className="col-md-6 dl2-sh__section5__col-right">
            <img
              src={require("../../assets/img/portfolio/dl2-stay-human_screen3.png")}
              alt="Day light 2 screenshots"
            />
            <img
              src={require("../../assets/img/portfolio/dl2-stay-human_screen4.png")}
              alt="Day light 2 screenshots"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid dl2-sh__section6 px-0">
        <div className="row">
          <div className="col-md-6 dl2-sh__section6__col-left order-2 order-md-1">
            <img
              src={require("../../assets/img/portfolio/dl2-stay-human_mac.png")}
              alt="Day light 2 screenshot"
            />
          </div>
          <div className="col-md-6 dl2-sh__list dl2-sh__section6__col-right order-1 order-md-2">
            <h2>Publikacja o 21:27</h2>
            <p>
              Uruchomić stronę (razem ze stroną pre orderową - zobacz case
              study) dokładnie 27 maja 2021 o godzinie 21:27. 21:27!
            </p>
            <p>[CIEKAWOSTKA]</p>
            <div className="dl2-sh__section6__redbanner">
              <img
                src={require("../../assets/img/portfolio/dl2-stay-human_redstripe.png")}
                alt="Red banner"
              />
              <h2>DLACZEGO 21:27?</h2>
            </div>
            <p>
              Odpowiedź na to pytanie znajdziesz w case study strony PRE-ORDER
              Dying Light 2.
            </p>
          </div>
        </div>
      </section>

      <section className="container-fluid dl2-sh__section7">
        <div className="row">
          <div className="col-lg-6 dl2-sh__list dl2-sh__section7__col-left">
            <h2>Efekt</h2>
            <p>
              1. Projekt zrealizowaliśmy w oparciu o Gatsby.js a stronę
              umieściliśmy w skalowalnej infrastrukturze chmurowej, dzięki czemu
              działa ona bardzo szybko nawet przy ogromnych ruchu napływającym
              ze wszystkim zakątków świata.
            </p>
            <p>
              2. Wiele wersji językowych = konieczność zaserwowania odpowiedniej
              z nich odpowiedniemu odwiedzającemu. Aby zrealizować to założenie
              postanowiliśmy, rozpoznawać w czasie rzeczywistym lokalizację IP
              użytkowników i na bazie tej informacji wybierać odpowiedni język
              serwisu. Easy.
            </p>
            <p>
              3. Oczywiście cały czas trzymaliśmy kciuki za pomyślne
              uruchomienie strony o wskazanej godzinie, ale nie tylko to
              zapewniło nam sukces. Dzięki wykonanym wcześniej, w bliźniaczych
              dla środowiska produkcyjnego warunkach, testom znaliśmy dokładny
              czas trwania budowania aplikacji (dowiedz się więcej o Gatsby
              tutaj) , wystarczyło więc kliknąć w odpowiednim czasie wielki
              czerwony przycisk i liczyć na to, że na planecie Ziemia nie
              nastąpi nagły blackout (nie nastąpił-przyp.WK).
            </p>
          </div>
          <div className="col-lg-6 dl2-sh__section7__col-right">
            <img
              src={require("../../assets/img/portfolio/dl2-stay-human_tablet1.png")}
              alt="Day light 2 tablet screenshot"
            />
          </div>
        </div>
        <img
          className="dl2-sh__section7__background d-none d-lg-block"
          src={require("../../assets/img/portfolio/dl2-stay-human_darkness.png")}
          alt="Day light 2 screenshot"
        />
      </section>

      <section className="container-fluid dl2-sh__section8 px-0">
        <img
          src={require("../../assets/img/portfolio/dl2-stay-human_banner2.jpg")}
          alt="Day light 2 screenshot"
        />
      </section>

      <section className="container-fluid dl2-sh__section9">
        <div className="row">
          <div className="col-md-6 dl2-sh__section9__col-left order-2 order-md-1">
            <img
              src={require("../../assets/img/portfolio/dl2-stay-human_tablet2.png")}
              alt="Day light 2 screenshot"
            />
          </div>
          <div className="col-md-6 dl2-sh__list dl2-sh__section9__col-right order-1 order-md-2">
            <h2>Czy wiesz że?</h2>
            <p>
              Mamy jeszcze więcej zombiaków. Wspólnie z Techland zmalowaliśmy
              jeszcze kilka fajnych projektów!
            </p>
            <p className="dl2-sh__section9__products">
              Dying Light 2 PRE-ORDER<br></br>
              Dying Light 2 Which Monster<br></br>
              Dying Light<br></br>
              Dying Light Nintendo Switch<br></br>
            </p>
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default DyingLight2StayHuman;
